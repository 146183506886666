import { gql } from '@apollo/client'
import md5 from 'md5'
import moment from 'moment'
import { FaArrowRight } from 'react-icons/fa'
import i18n from 'src/i18n.config'

import { ALERTS } from 'src/utils/Constants'
import Cookies from 'universal-cookie'
import apolloClient from '../Apollo'
import LANGUAGES from 'src/constants/languages'

const cookies = new Cookies()

export async function fetchMe() {
  const id = localStorage.getItem('id')
  try {
    const result = await apolloClient.query({
      query: gql`
        query fetchMe($id: ID!) {
          usersPermissionsUser(id: $id) {
            data {
              id
              attributes {
                username
                email
                language
                config
                openai
                preferences
                os
                role {
                  data {
                    id
                    attributes {
                      name
                      type
                      description
                    }
                  }
                }
                stats
              }
            }
          }
        }
      `,
      variables: {
        id,
      },
    })

    return result.data?.usersPermissionsUser?.data || null
  } catch (error) {
    console.log('error', error)
  }
}

export const getError = (error) => {
  if (error?.message) {
    if (error.message.key) {
      return error.message.key
    } else {
      return error.message
    }
  }
}

export const formatPrice = (float, currency = 'EUR') => {
  return new Intl.NumberFormat('fr-FR', {
    style: 'currency',
    currency,
    currencyDisplay: 'narrowSymbol',
  }).format(float)
}

export const formatNumber = (x, precision = null) => {
  if (x === undefined || x === null) {
    return 'N/A'
  }
  if (precision != null) {
    x = Math.round(x * Math.pow(10, precision)) / Math.pow(10, precision)
  }
  var parts = x.toString().split('.')
  if (precision) {
    parts = x.toFixed(precision).split('.')
  }
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
  return parts.join(',')
}

export const formatPercentage = (float, decimals = 0) => {
  return `${parseFloat(float * 100).toFixed(decimals)}%`
}

export const calculateArticlePrice = (priceType, priceUnit, contentLength = 0) => {
  let price = null

  if (priceType === 'by_word') {
    price = parseInt(contentLength) * parseFloat(priceUnit)
  } else if (priceType === 'by_article') {
    price = parseFloat(priceUnit)
  }

  return price
}

export const countWords = (title, content) => {
  return getNumberOfWords(title) + getNumberOfWords(content)
}

const getNumberOfWords = (content) => {
  if (content) {
    content = content.replace(/é|à|è/gi, 'e')
    // Remove special characters
    content = content.replace(/[^\w\s]/, '')
    // Tabs to spaces
    content = content.replace('\t', '    ')
    // More than 1 space to 4 spaces content = content.replace(/[ ]{2,}/, '    ')
    // 2 spaces or more to 1 space
    content = content.replace(/[ ]{2,}/, ' ')
    // Footnotes
    content = content.replace(/^\[[^]]*\][^(].*/, '')
    // Indented blocks of code
    content = content.replace(/^( {4,}[^-*]).*/, '')
    // Custom header IDs
    //text = content.sub(/{//.*}/, '', text)
    // Replace newlines with spaces for uniform handling
    content = content.replace('\n', ' ')
    // Remove images
    content = content.replace(/!\[[^\]]*\]\([^)]*\)/, '')
    // Remove HTML tags
    //text = content.sub(/</?[^>]*>/, '')
    // Remove footnote references
    content = content.replace(/\[[0-9]*\]/, '')
    // Remove enumerations
    //text = content.sub(/[0-9//]*\/, '', text)
    content = content.replace(/[0-9//]*\\/, '')
    // Links
    content = content.replace(/\[([^[]+)\](\(.*\))/gm, 'link')

    return content?.split(/\S+/).length - 1
  }
  return 0
}

export const getValidityText = (validityStartAt, validityEndAt) => {
  if (validityStartAt === null && validityEndAt === null) return '-'
  if (validityStartAt !== null && validityEndAt !== null)
    return (
      <>
        {moment(validityStartAt).format('DD MMM')}
        <FaArrowRight className="mx-1" />
        {moment(validityEndAt).format('DD MMM YYYY')}
      </>
    )
  if (validityStartAt && !validityEndAt)
    return 'À partir du ' + moment(validityStartAt).format('DD MMM YYYY')
  if (!validityStartAt && validityEndAt)
    return "Jusqu'au " + moment(validityEndAt).format('DD MMM YYYY')
}

export const getProgression = (me, offer) => {
  if (!me || !offer) return null
  let configRequired = offer?.product?.data?.attributes?.config?.toolso?.emailing?.enabled ?? false

  if (me?.attributes?.role.data.attributes.name !== 'admin') {
    configRequired = false
  }

  const fields = getProgressionDetails(offer, configRequired)

  let total = 0
  fields.map((field) => (total += field.isValid))
  const result = total / fields.length
  return result
}

export const getProgressionDetails = (offer, toolsoConfigRequired = true) => {
  if (typeof offer === 'undefined' || !offer || offer === null) return []

  let fields = [
    { label: i18n.t('common.forms.product.label'), isValid: !!offer.product?.data },
    { label: i18n.t('common.forms.name.label'), isValid: offer?.name !== '' },
    // { label: i18n.t('common.forms.redirectLink.label'), isValid: offer?.redirectLink !== '' },
    { label: i18n.t('common.forms.content.label'), isValid: offer?.content !== '' },
    { label: i18n.t('common.forms.deadline.label'), isValid: offer?.deadline !== null },
    // { label: 'Date de fin de validité', isValid: offer?.validityEndAt !== null },
    { label: i18n.t('common.forms.image.label'), isValid: offer.image?.data !== null },
    ...(offer?.product?.data?.attributes?.config?.wordpress?.content?.categoriesEnabled === true
      ? [
          {
            label: i18n.t('common.forms.categories.label'),
            isValid: offer.categories?.data?.length > 0,
          },
        ]
      : []),
    ...(offer?.product?.data?.attributes?.config?.wordpress?.content?.typesEnabled === true
      ? [
          {
            label: i18n.t('common.forms.types.label'),
            isValid: offer.types?.data?.length > 0,
          },
        ]
      : []),
    ...(offer?.product?.data?.attributes?.config?.wordpress?.content?.tagsEnabled === true
      ? [
          {
            label: i18n.t('common.forms.tags.label'),
            isValid: offer.tags?.data?.length > 0,
          },
        ]
      : []),
    //{ label: i18n.t('common.forms.types.label'), isValid: offer.types?.data?.length > 0 },
  ]

  if (toolsoConfigRequired === true) {
    fields = [
      ...fields,
/*       {
        label: 'SenderNames',
        isValid: offer?.config?.senderNames
          ? offer?.config?.senderNames[0]?.value?.length > 0
          : false,
      }, */
      {
        label: 'Subjects',
        isValid: offer?.config?.subjects ? offer?.config?.subjects[0]?.value?.length > 0 : false,
      },
    ]
  }

  return fields
}

export const updateCalendarFilters = (filters) => {
  cookies.set('calendarFilters', JSON.stringify(filters), { path: '/' })
}

export const getDuplicatedLink = async (link, offerId = 0) => {
  if (link.length === 0) return []

  const result = await apolloClient.query({
    query: gql`
      query($link: String!, $id: ID!) {
        offers(filters: { redirectLink: { contains: $link }, id: { ne: $id } }) {
          data {
            id
            attributes {
              name
              redirectLink
              config
            }
          }
        }
      }
    `,
    variables: {
      link,
      id: offerId,
    },
  })

  if (result.data.offers.data.length > 0) {
    return result.data.offers.data
  } else {
    return []
  }
}

export const getDuplicatedLinkFromWebsites = async (link, by, id) => {
  if (link.length === 0) return []

  const result = await apolloClient.mutate({
    mutation: gql`
      mutation($link: String!, $by: String, $id: ID!) {
        checkRedirectLink(link: $link, by: $by, id: $id) {
          status
          hasDuplicate
          duplicatedLinks
        }
      }
    `,
    variables: {
      link,
      by,
      id,
    },
  })

  if (result.data?.checkRedirectLink?.status === 'success') {
    return result.data.checkRedirectLink.duplicatedLinks
  } else {
    return []
  }
}

export const ucfirst = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1)
}

export const limitLength = (string, limit = 200) => {
  if (string.length > limit) {
    string = string.substring(0, limit) + '...'
  }

  return string
}

export const getLanguage = () => {
  let language = cookies.get('language') || 'fr'

  return {
    ...LANGUAGES[language],
  }
}

export const updateLanguage = (language) => {
  i18n.changeLanguage(language)
  cookies.set('language', language, { path: '/' })
}

export const getAlerts = () => {
  let alertsAlreadySeen = cookies.get('alerts') || null

  let alerts = ALERTS.filter((alert) => {
    if (alertsAlreadySeen === null) return true
    return !alertsAlreadySeen.includes(alert.id)
  })

  return alerts
}

export const hideAlert = (alertId) => {
  let alertsAlreadySeen = cookies.get('alerts') || null

  if (alertsAlreadySeen === null) {
    alertsAlreadySeen = [alertId]
  } else {
    alertsAlreadySeen.push(alertId)
  }

  cookies.set('alerts', alertsAlreadySeen, { path: '/' })
}

export const getAvatarLink = (email) => {
  return `https://www.gravatar.com/avatar/${md5(email || '')}`
}

export const capitalizeFirstLetter = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1)
}

/* const colorsList = [
  'blue',
  'indigo',
  'purple',
  'pink',
  'red',
  'orange',
  'yellow',
  'green',
  'teal',
  'cyan',
  'gray',
  'gray-dark',
] */

const colorsList = ['success', 'info', 'warning', 'danger', 'primary', 'secondary', 'light', 'dark']

export const getColor = (index) => {
  //return colors[colorsList[index] ?? 'grey']['500']
  return colorsList[index] ?? 'grey'
}

export const CONTENT_STATUS = [
  { value: 'deal_proposal', label: 'Sujet à valider' },
  { value: 'writing', label: 'À rédiger' },
  { value: 'reviewing', label: 'À relire' },
  { value: 'published', label: 'Publié' },
  { value: 'refused', label: 'Refusé' },
  { value: 'failed', label: 'Échoué' },
  { value: 'in_progress', label: 'En cours...' },
]

export const getContentStatus = (value) => {
  const item = CONTENT_STATUS.find((type) => type.value === value)
  if (item) {
    return item
  }

  return null
}

export const getContentStatusLabel = (value) => {
  return getContentStatus(value)?.label || ''
}
