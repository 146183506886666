import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import mainFR from './translations/fr/main.json'
import mainEN from './translations/en/main.json'
import mainDE from './translations/de/main.json'
import mainES from './translations/es/main.json'
import mainIT from './translations/it/main.json'
import mainNL from './translations/nl/main.json'
import { getLanguage } from './utils/Common'

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  //.use(LanguageDetector)
  .init({
    compatibilityJSON: 'v4',
    fallbackLng: 'fr',
    resources: {
      fr: { translation: mainFR },
      en: { translation: mainEN },
      de: { translation: mainDE },
      es: { translation: mainES },
      it: { translation: mainIT },
      nl: { translation: mainNL },
    },
    lng: getLanguage()?.code,
    //lng: 'fr', // language to use, more information here: https://www.i18next.com/overview/configuration-options#languages-namespaces-resources
    // you can use the i18n.changeLanguage function to change the language manually: https://www.i18next.com/overview/api#changelanguage
    // if you're using a language detector, do not define the lng option

    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  })

export const { t } = i18n.t.bind(i18n)
export default i18n
