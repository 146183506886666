import { ApolloClient, ApolloLink, InMemoryCache } from '@apollo/client'
import { setContext } from '@apollo/client/link/context'
import { onError } from '@apollo/client/link/error'
import { createUploadLink } from 'apollo-upload-client'
import { FaExternalLinkAlt } from 'react-icons/fa'
import { toast } from 'react-toastify'
import i18n from 'src/i18n.config'
import { capitalizeFirstLetter } from './utils/Common'
import { DEFAULT_TOAST_CONFIG } from './utils/Constants'

let uri = 'http://localhost:1337/graphql'
if (process.env.NODE_ENV === 'production') {
  uri = 'https://api.yescontent.rocks/graphql'
}

const uploadLink = createUploadLink({ uri: (operation) => `${uri}#${operation.operationName}` })
//const uploadLink = createUploadLink({ uri })

const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  const token = localStorage.getItem('token')
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : '',
    },
  }
})

const errorLink = onError(({ graphQLErrors, operation, forward }) => {
  if (graphQLErrors) {
    //if (toastElement) toast.dismiss(toastElement)
    graphQLErrors.map((error) => {
      let message = error.message
      const translateKey = `common.toasts.errors.${message}`
      if (i18n.exists(translateKey)) {
        message = i18n.t(translateKey)
      }

      toast.error(message)
    })
    return forward(operation)
  }
})

const translateOp = (text, plural = false, gender = '') => {
  const opSuffix = gender === 'f' ? '_f' : ''
  const translateKey = `common.operations.${text}${opSuffix}_${plural ? 'other' : 'one'}`

  let trans = ''
  if (i18n.exists(translateKey)) {
    trans = i18n.t(translateKey)
  }

  return trans
}

const translateResource = (text, plural = false, gender = '') => {
  const suffix = gender === 'f' ? '_f' : ''
  const translateKey = `common.resources.${text.toLowerCase()}${suffix}_${plural ? 'other' : 'one'}`
  let trans = ''
  if (i18n.exists(translateKey)) {
    trans = i18n.t(translateKey)
  }
  return capitalizeFirstLetter(trans)
}

const getResourceGender = (text) => {
  const femWordsList = ['offer', 'brand', 'category', 'categories', 'organization']
  // check if text is in the list even with s at the end and non case sensitive
  if (
    femWordsList.includes(text.toLowerCase()) ||
    femWordsList.includes(text.toLowerCase().slice(0, -1))
  ) {
    return 'f'
  }
  return ''
}

const getResourcePlural = (text) => {
  if (text.slice(-1) === 's') return true
  return false
}

const notificationLink = new ApolloLink((operation, forward) => {
  let toastElement
  let operationType
  let operationName = operation?.operationName ?? ''
  if (operationName.includes('update')) {
    operationType = 'update'
  } else if (operationName?.includes('create')) {
    operationType = 'create'
  } else if (operationName.includes('delete')) {
    operationType = 'delete'
  } else if (operationName.includes('publish')) {
    operationType = 'publish'
  } else if (operationName.includes('fetch')) {
    operationType = 'fetch'
  } else if (operationName.includes('login')) {
    operationType = 'login'
  } else if (operationName.includes('send')) {
    operationType = 'send'
  } else if (operationName.includes('generate')) {
    operationType = 'generate'
  }

  let resource = operationName.split(operationType)[1] ?? ''
  let resourceGender = getResourceGender(resource)
  let resourcePlural = getResourcePlural(resource)

  if (['update', 'create', 'delete', 'publish', 'send'].includes(operationType)) {
    toastElement = toast.loading(
      `${translateResource(resource)} ${translateOp(
        operationType,
        resourcePlural,
        resourceGender,
      )}...`,
    )
  }
/*   if (operationType === 'generate') {
    toastElement = toast.loading(`${i18n.t(`common.toasts.generate.loading`)}`)
  } */

  return forward(operation).map((response) => {
    console.log('response', response)
    if (!response.errors) {
      if (operationType === 'login') {
        toast.success(i18n.t(`common.toasts.login.success`))
      }
    }

    if (operationType === 'generate') {
      // for each response?.data?.generateAllContent?.value.results

      console.log('response?.data?.generateAllContent?.value', response?.data?.generateAllContent?.value)

      for (const result of response?.data?.generateAllContent?.value.results) {
        console.log('result', result)
        if(result.status === 'error') {
          toast.error(result.message)
        }else{
          toast.success(i18n.t(`common.toasts.generate.success`))
        }
      }
    }

    if (toastElement) {
      if (response?.data?.publishOffer?.status === 'warning') {
        toast.update(toastElement, {
          ...DEFAULT_TOAST_CONFIG,
          type: toast.TYPE.WARNING,
          render: `🤯 ${translateResource(resource)} ${translateOp(
            operationType,
            resourcePlural,
            resourceGender,
          )}`,
        })
      } else {
        // Add redirect icon in toast
        toast.update(toastElement, {
          ...DEFAULT_TOAST_CONFIG,
          render: (
            <>
              {translateResource(resource)}{' '}
              {translateOp(operationType, resourcePlural, resourceGender)}
              {operationType === 'publish' ? (
                <FaExternalLinkAlt
                  style={{ marginLeft: '8px' }}
                  size="1rem"
                  onClick={() =>
                    window.open(`/#/offers/${response.data.publishOffer?.data?.id}`, '_blank')
                  }
                />
              ) : null}
            </>
          ),
        })
      }
    }

    return response
  })
})

const client = new ApolloClient({
  link: ApolloLink.from([notificationLink, authLink, errorLink, uploadLink]),
  cache: new InMemoryCache(),
  connectToDevTools: true,
  defaultOptions: {
    /*     watchQuery: {
      fetchPolicy: 'cache-and-network',
      errorPolicy: 'ignore',
    },
    query: {
      fetchPolicy: 'network-only',
      errorPolicy: 'all',
    },
    mutate: {
      errorPolicy: 'all',
    }, */
  },
})

export default client
