import React from 'react'
import { toast } from 'react-toastify'

export const DEFAULT_TOAST_CONFIG = {
  autoClose: 5000,
  type: toast.TYPE.SUCCESS,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: false,
  isLoading: false,
}

const CHANGELOG_TYPES = {
  improvment: {
    label: 'Amélioration',
    color: 'dark',
  },
  fix: {
    label: 'Correction',
    color: 'warning',
  },
  feature: {
    label: 'Fonctionnalité',
    color: 'info',
  },
}

export const CHANGELOG = [
  {
    date: '2022-07-05',
    changes: [
      {
        type: CHANGELOG_TYPES.improvment,
        content:
          "L'URL des bons plans est vérifiée directement sur les sites WordPress (plutôt que sur YesContent)",
      },
    ],
  },
  {
    date: '2022-07-06',
    changes: [
      {
        type: CHANGELOG_TYPES.fix,
        content:
          "L'option de vérification des bons plans est corrigée sur la liste principale et affiche lorsque le bon plan n'est pas utilisé",
      },
    ],
  },
  {
    date: '2022-07-08',
    changes: [
      {
        type: CHANGELOG_TYPES.feature,
        content:
          "Ajout des raccourcis clavier (enregistrer + sortir d'une offre) + Aide pour comprendre les raccourcis",
      },
      {
        type: CHANGELOG_TYPES.improvment,
        content: 'Le rédacteur peut supprimer des bons plans (pas encore publiés)',
      },
      {
        type: CHANGELOG_TYPES.fix,
        content: "Bug corrigé concernant la vérification du lien dans l'édition d'une offre",
      },
      {
        type: CHANGELOG_TYPES.fix,
        content: '⚠️ Les revenus sont calculés en fonction des articles publiées désormais ⚠️',
      },
    ],
  },
  {
    date: '2022-07-11',
    changes: [
      {
        type: CHANGELOG_TYPES.fix,
        content:
          "Date de fin de validité plus obligatoire dans l'édition d'une offre (il est possible de laisser la date de fin de validité vide)",
      },
      {
        type: CHANGELOG_TYPES.fix,
        content: "Bouton 'publier' masqué dans l'édition d'une offre pour le rédacteur",
      },
      {
        type: CHANGELOG_TYPES.fix,
        content:
          "La barre de progression est à 100% pour le rédacteur (même quand il n'y a pas les O/S)",
      },
    ],
  },
  {
    date: '2022-07-12',
    changes: [
      {
        type: CHANGELOG_TYPES.fix,
        content: 'Correction du bug dans les fitres des dates',
      },
    ],
  },
  {
    date: '2022-07-15',
    changes: [
      {
        type: CHANGELOG_TYPES.fix,
        content: 'Correction du bug des dates de publication',
      },
      {
        type: CHANGELOG_TYPES.fix,
        content: 'Quand on clique sur une offre dans le calendrier ça ouvre un nouvel onglet',
      },
      {
        type: CHANGELOG_TYPES.fix,
        content:
          'Vérification des URLs améliorées (recherche avec ou sans le / à la fin) et on vérifie sur les WordPress ET dans la base YesContent',
      },
      {
        type: CHANGELOG_TYPES.improvment,
        content:
          "Possibilité de drag & drop sur le calendrier pour changer la date de validité et de publication d'une offre",
      },
      {
        type: CHANGELOG_TYPES.improvment,
        content: 'Filtres des offres et du calendrier sauvegardé automatiquement',
      },
    ],
  },
  {
    date: '2022-07-20',
    changes: [
      {
        type: CHANGELOG_TYPES.fix,
        content:
          "Correction du bug des filtres qui ne s'affichaient pas quand on rechargeait la page",
      },
      {
        type: CHANGELOG_TYPES.fix,
        content:
          "Correction du comptage des revenus (à présent, tous les articles sont comptés dès l'instant où ils ont une deadline)",
      },
      {
        type: CHANGELOG_TYPES.improvment,
        content:
          "Le nombre d'offres à valider et à relire pour l'admin ET les offres à rédiger pour le rédacteur sont affichés dans la sidebar",
      },
      {
        type: CHANGELOG_TYPES.improvment,
        content: "Possibilité de changer le nombre d'offres affichées (dans les filtres)",
      },
      {
        type: CHANGELOG_TYPES.improvment,
        content: "Ajout de la colonne 'Prix' dans la liste des offres",
      },
      {
        type: CHANGELOG_TYPES.feature,
        content: "Ajout d'une barre de recherche par ID ou titre dans la liste des offres",
      },
      {
        type: CHANGELOG_TYPES.fix,
        content: 'Correction de la traduction',
      },
    ],
  },
  {
    date: '2022-07-22',
    changes: [
      {
        type: CHANGELOG_TYPES.improvment,
        content: "Barre de chargement en bas de l'édition d'une offre",
      },
      {
        type: CHANGELOG_TYPES.improvment,
        content:
          "Ajout du bouton des liens de redirection quand l'offre est publiée en bas de l'édition d'une offre",
      },
      {
        type: CHANGELOG_TYPES.improvment,
        content:
          'En cliquant sur un mois précis dans le tableau des revenus, ça affiche la liste des offres concernées',
      },
      {
        type: CHANGELOG_TYPES.feature,
        content:
          "On peut créer un 'Batch d'offres' depuis le modal de création (en résumé, créer plusieurs offres en même temps !)",
      },

      {
        type: CHANGELOG_TYPES.fix,
        content: 'Bug de la flèche retour quand on édite une offre',
      },
    ],
  },
  {
    date: '2022-07-22',
    changes: [
      {
        type: CHANGELOG_TYPES.fix,
        content: "La barre de recherche va chercher dans l'ID, le titre et le contenu",
      },
      {
        type: CHANGELOG_TYPES.improvment,
        content: 'Multi-select pour les produits dans les filtres du calendrier',
      },
    ],
  },
  {
    date: '2022-07-28',
    changes: [
      {
        type: CHANGELOG_TYPES.fix,
        content: 'Bug pour vérifier les liens dupliqués',
      },
      {
        type: CHANGELOG_TYPES.fix,
        content: "La barre de recherche va aussi chercher dans l'URL de l'offre",
      },
      {
        type: CHANGELOG_TYPES.fix,
        content: 'La barre de recherche va chercher parmi toute les offres (peu importe le statut)',
      },
      {
        type: CHANGELOG_TYPES.fix,
        content: 'Formulaire de création vidé quand une nouvelle offre est créée',
      },
      {
        type: CHANGELOG_TYPES.fix,
        content: 'Afficher les URL avec maximum de 55 caractères dans la liste des offres',
      },
      {
        type: CHANGELOG_TYPES.improvment,
        content: "Ajout de la colonne 'Actions' dans la personnalisation des colonnes",
      },
      {
        type: CHANGELOG_TYPES.feature,
        content: 'Vérification du duplicate interne',
      },
    ],
  },
  {
    date: '2022-08-01',
    changes: [
      {
        type: CHANGELOG_TYPES.fix,
        content: 'Bug des offres en échec corrigé',
      },
      {
        type: CHANGELOG_TYPES.improvment,
        content: 'Possibilité de modifier le statut des offres en publiée',
      },
      {
        type: CHANGELOG_TYPES.feature,
        content: 'Page de configuration des produits',
      },
      {
        type: CHANGELOG_TYPES.feature,
        content: 'Vérification du contenu dupliqué externe',
      },
      {
        type: CHANGELOG_TYPES.improvment,
        content: 'Tableau sur le dashboard pour voir les coûts',
      },
    ],
  },
  {
    date: '2022-08-04',
    changes: [
      {
        type: CHANGELOG_TYPES.improvment,
        content: 'Plusieurs langues pour les types et catégories',
      },
    ],
  },
  {
    date: '2022-08-11',
    changes: [
      {
        type: CHANGELOG_TYPES.fix,
        content: 'Sauvegarde automatique des filtres et des préférences améliorée',
      },
      {
        type: CHANGELOG_TYPES.improvment,
        content: 'Filtrer le calendrier par produit et rédacteur',
      },
    ],
  },
  {
    date: '2022-08-16',
    changes: [
      {
        type: CHANGELOG_TYPES.fix,
        content:
          "On peut copier/coller dans du texte dans l'éditeur de texte sans que ça garde la mise en forme",
      },
      {
        type: CHANGELOG_TYPES.improvment,
        content: 'Optimisations de la rapidité du tableau de bord',
      },
      {
        type: CHANGELOG_TYPES.improvment,
        content: "Ajout d'info bulles pour les actions dans la liste des offres",
      },
      {
        type: CHANGELOG_TYPES.improvment,
        content:
          'On peut ajouter des titres dans les offres (H2 pour un gros titre et H3 pour un petit titre)',
      },
      {
        type: CHANGELOG_TYPES.fix,
        content: 'Bug de comptage des offres dans la sidebar (à écrire et à publier)',
      },

      {
        type: CHANGELOG_TYPES.feature,
        content: (
          <>
            Comment vérifier les fautes d'orthographe dans les offres ?{' '}
            <a
              rel="noreferrer"
              target="_blank"
              href="https://tella.video/yescontent-comment-verifier-les-fautes-dorthographe-hqwn"
            >
              Voir le tutoriel
            </a>
          </>
        ),
      },
    ],
  },
  {
    date: '2022-08-17',
    changes: [
      {
        type: CHANGELOG_TYPES.improvment,
        content: 'Ajout de flèche précédent/suivant',
      },
    ],
  },
  {
    date: '2022-08-22',
    changes: [
      {
        type: CHANGELOG_TYPES.feature,
        content: "Ajout d'une page O/S pour les chefs de produit",
      },
    ],
  },
  {
    date: '2022-08-23',
    changes: [
      {
        type: CHANGELOG_TYPES.feature,
        content:
          'Amélioration de la page O/S pour les chefs de produit (filtrer par produit et rédacteur)',
      },
    ],
  },
  {
    date: '2022-08-25',
    changes: [
      {
        type: CHANGELOG_TYPES.improvment,
        content:
          "On peut préciser la catégorie de l'offre dans le formulaire de création (dans la liste des offres)",
      },
      {
        type: CHANGELOG_TYPES.improvment,
        content: "Notifications déplacées en bas à gauche avec un design plus 'coloré'",
      },
      {
        type: CHANGELOG_TYPES.fix,
        content: 'Correction des flèches offres suivantes/précédentes',
      },
    ],
  },
  {
    date: '2022-08-29',
    changes: [
      {
        type: CHANGELOG_TYPES.fix,
        content: "Fix du bug d'enregistrement de l'offre avec le raccourci clavier",
      },
      {
        type: CHANGELOG_TYPES.fix,
        content: 'Champ rédacteur masqué dans le formulaire de création',
      },
    ],
  },
  {
    date: '2022-08-30',
    changes: [
      {
        type: CHANGELOG_TYPES.feature,
        content:
          'Possibilité de modifier les offres depuis le calendrier (en cliquant sur une offre)',
      },
      {
        type: CHANGELOG_TYPES.feature,
        content: 'Possibilité de créer des offres depuis le calendrier (en cliquant sur une date)',
      },
      {
        type: CHANGELOG_TYPES.improvment,
        content: "Ajout d'un système de détection de nouvelle version",
      },
    ],
  },
  {
    date: '2022-09-05',
    changes: [
      {
        type: CHANGELOG_TYPES.improvment,
        content:
          "Trier sur l'ensemble des offres (au lieu de la page courante dans la liste des offres)",
      },
      {
        type: CHANGELOG_TYPES.improvment,
        content: 'Enregistrement automatique du trie',
      },
      {
        type: CHANGELOG_TYPES.improvment,
        content: "Ajout d'un bouton pour réinitialiser les fitlres sur la liste des offres",
      },
      {
        type: CHANGELOG_TYPES.improvment,
        content: 'Possibilité de trier par date de validité (dans la liste des offres)',
      },
    ],
  },
  {
    date: '2022-09-06',
    changes: [
      {
        type: CHANGELOG_TYPES.fix,
        content: 'Correction de bugs',
      },
    ],
  },
  {
    date: '2022-09-06',
    changes: [
      {
        type: CHANGELOG_TYPES.fix,
        content: 'Correction de bugs',
      },
    ],
  },
  {
    date: '2022-09-07',
    changes: [
      {
        type: CHANGELOG_TYPES.fix,
        content: 'Correction de bugs',
      },
    ],
  },
  {
    date: '2022-09-13',
    changes: [
      {
        type: CHANGELOG_TYPES.feature,
        content: "Ajout d'une messagerie sur les offres",
      },
    ],
  },
  {
    date: '2022-09-14',
    changes: [
      {
        type: CHANGELOG_TYPES.improvment,
        content: 'Affichage des messages dans un onglet de notifications',
      },
    ],
  },
  {
    date: '2022-09-15',
    changes: [
      {
        type: CHANGELOG_TYPES.fix,
        content: "Bug résolu sur la création d'offre avec une URL de plus de 255 caractères",
      },
      {
        type: CHANGELOG_TYPES.improvment,
        content: 'Remplissage automatique de la deadline depuis le calendrier',
      },
    ],
  },
  {
    date: '2022-09-16',
    changes: [
      {
        type: CHANGELOG_TYPES.improvment,
        content: 'Notifications affichées que pour les produits qui nous concernent',
      },
      {
        type: CHANGELOG_TYPES.improvment,
        content:
          "Possibilité d'ouvrir une offre dans un nouvel onglet en faisant command/ctrl + click dans le calendrier",
      },
      {
        type: CHANGELOG_TYPES.improvment,
        content: 'Amélioration du système de notifications',
      },
    ],
  },
  {
    date: '2022-09-19',
    changes: [
      {
        type: CHANGELOG_TYPES.improvment,
        content: 'Tarification et configuration sur des pages séparées',
      },
      {
        type: CHANGELOG_TYPES.fix,
        content: 'Fix du bug de vérification de duplicate',
      },
    ],
  },
  {
    date: '2022-09-20',
    changes: [
      {
        type: CHANGELOG_TYPES.fix,
        content: 'Correction du ctrl+clic sur les offres dans le calendrier',
      },
      {
        type: CHANGELOG_TYPES.improvment,
        content: 'Affichage du contenu des offres dans la page O/S',
      },

      {
        type: CHANGELOG_TYPES.improvment,
        content:
          'Rechargement automatiquement de la liste des offres quand on ajoute une offre (ou mise à jour)',
      },
    ],
  },
  {
    date: '2022-09-23',
    changes: [
      {
        type: CHANGELOG_TYPES.fix,
        content: 'Correction du bug dans le calendrier',
      },
    ],
  },
  {
    date: '2022-09-27',
    changes: [
      {
        type: CHANGELOG_TYPES.feature,
        content: 'Ajout de la traduction (pas complété)',
      },
      {
        type: CHANGELOG_TYPES.improvment,
        content: 'Ajout du bouton "lu" dans la popup de notification et ajout du scroll',
      },
    ],
  },
  {
    date: '2022-09-27',
    changes: [
      {
        type: CHANGELOG_TYPES.improvment,
        content:
          'Affichage plus complet des stats sur la page des détails des revenus et la possiblité de mettre à jour le prix des offres',
      },
      {
        type: CHANGELOG_TYPES.feature,
        content: "Envoie d'un email après le dépôt d'une facture",
      },
      {
        type: CHANGELOG_TYPES.feature,
        content:
          "Envoie d'un email automatique de rappel pour déposer sa facture en tant que rédacteur",
      },
      {
        type: CHANGELOG_TYPES.fix,
        content: 'Ne pas afficher les offres refusées par défaut',
      },
      {
        type: CHANGELOG_TYPES.fix,
        content:
          'Bug corrigé qui laissait apparaitre le résultat de la vérification de lien dans le modal de création',
      },
    ],
  },
  {
    date: '2022-10-04',
    changes: [
      {
        type: CHANGELOG_TYPES.improvment,
        content: "Bouton pour changer le statut directement depuis l'offre",
      },
    ],
  },
  {
    date: '2022-10-10',
    changes: [
      {
        type: CHANGELOG_TYPES.fix,
        content:
          "Quand on change le statut d'une offre, il y avait un bug qui supprimait les O/S => c'est corrigé",
      },
      {
        type: CHANGELOG_TYPES.fix,
        content: 'Les rédacteurs ne verront pas les messages des autres rédacteurs',
      },
      {
        type: CHANGELOG_TYPES.fix,
        content: "Sur la page O/S, j'affiche la deadline et la date de validité",
      },
    ],
  },
  {
    date: '2022-10-21',
    changes: [
      {
        type: CHANGELOG_TYPES.fix,
        content: 'Nouveau système de mise à jour',
      },
    ],
  },
  {
    date: '2022-11-02',
    changes: [
      {
        type: CHANGELOG_TYPES.feature,
        content: "Ajout d'une page 'mon compte' pour changer son email",
      },
      {
        type: CHANGELOG_TYPES.feature,
        content: "Ajout d'une option 'permanent' pour les offres",
      },
    ],
  },
  {
    date: '2022-11-22',
    changes: [
      {
        type: CHANGELOG_TYPES.fix,
        content: 'Amélioration globale des performances',
      },
      {
        type: CHANGELOG_TYPES.feature,
        content: "Ajout d'une boite de message dans le modal de création d'une offre",
      },
    ],
  },
  {
    date: '2022-11-25',
    changes: [
      {
        type: CHANGELOG_TYPES.fix,
        content: 'Correction du bug de performance',
      },
    ],
  },
  {
    date: '2022-12-08',
    changes: [
      {
        type: CHANGELOG_TYPES.improvment,
        content: 'Traduction totale du site',
      },
    ],
  },
  {
    date: '2022-12-12',
    changes: [
      {
        type: CHANGELOG_TYPES.feature,
        content: 'Ajout du multi-organisations',
      },
    ],
  },
  {
    date: '2022-12-13',
    changes: [
      {
        type: CHANGELOG_TYPES.improvment,
        content: "Sélection de 'organisations automatiquement si l'utilisateur n'en a qu'une",
      },
      {
        type: CHANGELOG_TYPES.fix,
        content:
          "Bug qui remplissait la 'variable' du champs Preview Text au lieu du Content Title",
      },
      {
        type: CHANGELOG_TYPES.fix,
        content: 'Le calendrier se réactualise quand on ajoute une nouvelle offre',
      },
      {
        type: CHANGELOG_TYPES.fix,
        content:
          "À partir du calendrier, quand on modifie une offre et qu'on veut vérifier le lien, on avait une erreur => c'est corrigé",
      },
      {
        type: CHANGELOG_TYPES.feature,
        content: "Fonctionnalité : envoyer un email à tous les rédacteurs d'une organisation",
      },
    ],
  },
  {
    date: '2022-12-27',
    changes: [
      {
        type: CHANGELOG_TYPES.fix,
        content: 'Quelques soucis de traduction corrigés',
      },
      {
        type: CHANGELOG_TYPES.fix,
        content: 'Correction du bug des messages non lus',
      },
      {
        type: CHANGELOG_TYPES.fix,
        content: "Sauveagarde automatique de l'image quand on l'upload dans une offre",
      },
    ],
  },
  {
    date: '2023-01-10',
    changes: [
      {
        type: CHANGELOG_TYPES.feature,
        content: "Afficher les stats d'emailing sur la page d'O/S et dans une offre publiée",
      },
    ],
  },
  {
    date: '2023-01-13',
    changes: [
      {
        type: CHANGELOG_TYPES.feature,
        content: "Affichage des statistiques d'emailing dans la page d'O/S terminé",
      },
      {
        type: CHANGELOG_TYPES.feature,
        content: "Ajout de l'option pour traduire les contenus (et O/S)",
      },
    ],
  },
  {
    date: '2023-01-16',
    changes: [
      {
        type: CHANGELOG_TYPES.fix,
        content: "Correction de l'affichage des statistiques d'emailing dans la page d'O/S",
      },
      {
        type: CHANGELOG_TYPES.fix,
        content: 'Correction de la traduction des contenus (et O/S)',
      },
      {
        type: CHANGELOG_TYPES.improvment,
        content: "Possibilité d'ouvrir plusieurs offres en 1 clic depuis la page des offres",
      },
    ],
  },
  {
    date: '2023-01-24',
    changes: [
      {
        type: CHANGELOG_TYPES.improvment,
        content: 'Possibilité de voir les offres par statut ou par type depuis le calendrier',
      },
      {
        type: CHANGELOG_TYPES.improvment,
        content:
          "Possibilité d'ouvrir en 1 clic les redirections des offres ou les offres dans YesContent depuis la page des offres",
      },
    ],
  },
  {
    date: '2023-02-02',
    changes: [
      {
        type: CHANGELOG_TYPES.improvment,
        content: "Possibilité d'ajouter un brief sur la rédaction de contenu",
      },
      {
        type: CHANGELOG_TYPES.improvment,
        content:
          "Ajout de l'option du gras, de l'italique et des couleurs dans l'éditeur de contenu",
      },
    ],
  },
  {
    date: '2023-02-13',
    changes: [
      {
        type: CHANGELOG_TYPES.improvment,
        content: 'Traduction des variables additionnelles dans le modal de prévisualisation',
      },
    ],
  },
  {
    date: '2023-02-22',
    changes: [
      {
        type: CHANGELOG_TYPES.improvment,
        content: 'Refonte graphique totale du site',
      },
    ],
  },
  {
    date: '2023-02-28',
    changes: [
      {
        type: CHANGELOG_TYPES.improvment,
        content:
          'Ajout des Étiquettes comme taxonomie et permet de publier dans des types de publication WordPress différents',
      },
      {
        type: CHANGELOG_TYPES.feature,
        content: 'Ajout de shortcode dans le contenu des offres',
      },
    ],
  },
  {
    date: '2023-04-28',
    changes: [
      {
        type: CHANGELOG_TYPES.improvment,
        content: 'Ajout du champ "contenu permanent" dans le modal de création d\'offre',
      },
    ],
  },
  {
    date: '2023-05-16',
    changes: [
      {
        type: CHANGELOG_TYPES.improvment,
        content: 'Possibilité de ne pas mettre de prix dans une offre',
      },
      {
        type: CHANGELOG_TYPES.improvment,
        content:
          "Possibilité d'afficher les warning des champs quand on sauvegarde une offre (option à cocher dans la page préférences)",
      },
    ],
  },
  {
    date: '2023-06-22',
    changes: [
      {
        type: CHANGELOG_TYPES.feature,
        content: 'YesContent AI ☀️ : génération automatique de contenu',
      },
    ],
  },
]

const ALERT_TYPES = {
  warning: 'warning',
  success: 'success',
  info: 'info',
  danger: 'danger',
}

export const ALERTS = [
  /*   {
    id: 1,
    date: '2022-09-08',
    type: ALERT_TYPES.warning,
    content:
      "Suite à une mise à jour, les préférences ont été réinitialisées. C'est donc normal si l'affichage des offres est de nouveau \"par défaut\". Vous pouvez refaire votre trie/filtrage. Cette nouvelle mise à jour corrige le souci lié aux tarifs qui ne s'ajoutaient pas aux offres après leur création.",
  }, */
  /*     {
    id: 2,
    date: '2022-09-26',
    type: ALERT_TYPES.info,
    content:
      "Comment vérifier ses fautes d'orthographe ? Une vidéo explicative de 2 min pour vous montrer 👉 [cliquez-ici](https://tella.video/yescontent-comment-verifier-les-fautes-dorthographe-hqwn)",
  }, */
  /*   {
    id: 'drop_invoices',
    date: '2022-09-30',
    type: ALERT_TYPES.info,
    content:
      'Comment déposer sa facture **directement sur YesContent ?** Une vidéo explicative de 40 sec pour vous montrer 👉 [cliquez-ici](https://tella.video/deposer-sa-facture-sur-yescontent-9sp5)',
  }, */
  /*   {
    id: 'reminder_invoices_october',
    date: '2022-11-03',
    type: ALERT_TYPES.info,
    content:
      "N'oubliez pas de déposer votre facture pour le mois d'octobre ! Vidéo de démonstration ici 👉 [cliquez-ici](https://tella.video/deposer-sa-facture-sur-yescontent-9sp5)",
  }, */
  /*   {
    id: 'reminder_invoices',
    date: '2022-11-03',
    type: ALERT_TYPES.info,
    content:
      'Pour recevoir une alerte par email pour le dépôt de facture, veuillez changer votre adresse email personnelle sur YesContent ! Voir comment changer 👉 [cliquez-ici](https://media.cleanshot.cloud/media/23224/cocSfylF4hFyUncjwwwogaA9TUw5aRNYC72RIwsQ.jpeg?Expires=1667488065&Signature=jIUd8je00GYpivNusFKWdrVgTcwpWlFw9AbP6vagjsAkVozvHJMTKlLqXVj7yrOZjnF5IhT-AEPbQqsUf-5YIkD5kARst~HwahluPN3rNuMiO6FBFWuXvG-t0MVQ8VKVwyyP9J5iBaI8OTexd5a~FuN0ztDydC-l4gLpfQ6pSG6pZei53f91NuWYxln-C7COaQ~Fnz7YXoy~B9U2Rs0NcmLQH60vmgTYcvfaH5LfBn72mzJlSwM9CKl7U5km00FrTBU4c2uBBmMcD2S-SvN4ashOyqlSDcRFqpB7w8P5eRH8-mm2wbYbePL-p0W-QapVXPRFTwe6rkeluZguX2cpng__&Key-Pair-Id=K269JMAT9ZF4GZ)',
  }, */
  /*   {
    id: 'organization_support',
    date: '2022-12-12',
    type: ALERT_TYPES.warning,
    content:
      "YesContent vient d'être mis à jour ce 12 décembre pour supporter donner la possibilité d'avoir plusieurs entreprises (organisations). Ça ne change pratiquement rien pour les rédacteurs. Cependant, si vous rencontrez un bug quelconque, veuillez contacter le développeur sur l'email suivant : axel.paris@deux-trente.com",
  }, */
]
