import { gql } from '@apollo/client'
import Cookies from 'universal-cookie'
import apolloClient from '../Apollo'
import { isLoggedInVar } from './../cache'

export async function logout() {
  const cookies = new Cookies()
  cookies.remove('organizationId')
  cookies.remove('token')
  cookies.remove('id')
  localStorage.removeItem('organizationId')
  localStorage.removeItem('token')
  localStorage.removeItem('id')
  apolloClient.resetStore()
  isLoggedInVar(false)
}

export function getLocalData() {
  const cookies = new Cookies()
  let token = cookies.get('token') || localStorage.getItem('token') || null
  let id = cookies.get('id') || localStorage.getItem('id') || null

  return { token, id }
}

export function getOrganizationId() {
  const cookies = new Cookies()
  let organizationId =
    cookies.get('organizationId') || localStorage.getItem('organizationId') || null

  return organizationId
}

export function setOrganizationId(organizationId) {
  const cookies = new Cookies()
  cookies.set('organizationId', organizationId, { path: '/' })

  localStorage.setItem('organizationId', organizationId)
}

export function isLogged() {
  const cookies = new Cookies()
  let token = cookies.get('token') || localStorage.getItem('token') || null

  if (token) return true
  return false
}

export async function updateUser(id, data, silent = false) {
  try {
    const result = await apolloClient.mutate({
      variables: { id, data },
      mutation: gql`
        mutation ${
          silent === true ? '' : 'updateUser'
        }($id: ID!, $data: UsersPermissionsUserInput!) {
          updateUsersPermissionsUser(id: $id, data: $data) {
            data {
              id
              attributes {
                config
              }
            }
          }
        }
      `,
    })

    return result?.data?.updateUsersPermissionsUser?.data || null
  } catch (error) {
    console.log('error', error)
  }
}

export async function updateMe(data, silent = false) {
  try {
    const id = localStorage.getItem('id')
    await updateUser(id, data, silent)
  } catch (error) {
    console.log('error', error)
  }
}

export const fetchAdmins = async (filters = null) => {
  const organizationId = localStorage.getItem('organizationId')

  const result = await apolloClient.query({
    query: gql`
      query fetchAmins($filters: UsersPermissionsUserFiltersInput) {
        usersPermissionsUsers(filters: $filters) {
          data {
            id
            attributes {
              email
              role {
                data {
                  id
                  attributes {
                    name
                    description
                  }
                }
              }
              products {
                data {
                  id
                  attributes {
                    name
                  }
                }
              }
              username
              stats
              blocked
              config
              openai
            }
          }
        }
      }
    `,
    variables: {
      filters: {
        organizations: {
          id: {
            eq: organizationId,
          },
        },
        blocked: {
          eq: false,
        },
        role: { name: { eq: 'admin' } },
        ...filters,
      },
    },
  })

  return result?.data?.usersPermissionsUsers?.data || []
}

export const fetchUser = async (userId = null) => {
  try {
    const result = await apolloClient.query({
      query: gql`
      query fetchUser${userId}($id: ID!) {
        usersPermissionsUser(id: $id) {
          data {
            id
            attributes {
              username
              email
              role {
                data {
                  attributes {
                    name
                  }
                }
              }
            }
          }
        }
      }

      `,
      variables: {
        id: userId,
      },
    })

    return result?.data?.usersPermissionsUser?.data || null
  } catch (error) {
    console.log('error', error)
  }
}
