import { InMemoryCache, makeVar } from '@apollo/client'
import { isLogged } from './services/User'
// Initializes to true if localStorage includes a 'token' key,
// false otherwise
export const isLoggedInVar = makeVar(isLogged())

// Initializes to an empty array

export const cache = new InMemoryCache({
  typePolicies: {
    // Type policy map
    Query: {
      fields: {
        // Field policy map for the Product type
        isLoggedIn: {
          read() {
            return isLoggedInVar()
          },
        },
      },
    },
  },
})
