export default {
  fr: {
    name: 'Français',
    code: 'fr',
    flag: 'fr',
    fullCode: 'fr_FR',
  },
  en: {
    name: 'English',
    code: 'en',
    flag: 'us',
    fullCode: 'en_US',
  },
  de: {
    name: 'Deutsch',
    code: 'de',
    flag: 'de',
    fullCode: 'de_DE',
  },
  es: {
    name: 'Spanish',
    code: 'es',
    flag: 'es',
    fullCode: 'es_ES',
  },
  it: {
    name: 'Italian',
    code: 'it',
    flag: 'it',
    fullCode: 'it_IT',
  },
  nl: {
    name: 'Nederlands',
    code: 'nl',
    flag: 'nl',
    fullCode: 'nl_NL',
  },
}
