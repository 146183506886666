import { ApolloProvider } from '@apollo/client'
import React from 'react'
import { HashRouter, Navigate, Route, Routes, useLocation } from 'react-router-dom'
import './i18n.config'

import { useReactiveVar } from '@apollo/client'
import { isLoggedInVar } from './cache'

import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import client from './Apollo'

import './scss/style.scss'
import AnchorScroller from 'src/components/AnchorScroller.tsx'

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
)

// Containers
const DefaultLayout = React.lazy(() => import('./layout/DefaultLayout'))

// Pages
const Login = React.lazy(() => import('./views/pages/login/Login'))

const App = () => {
  return (
    <HashRouter>
      <ToastContainer
        position="bottom-left"
        newestOnTop={false}
        autoClose={5000}
        hideProgressBar={false}
        closeOnClick={true}
        pauseOnHover={false}
        theme={'colored'}
      />
      <AnchorScroller>
        <ApolloProvider client={client}>
          <React.Suspense fallback={loading}>
            <Routes>
              <Route
                path="/login"
                element={
                  <RequireNotAuth>
                    <Login />
                  </RequireNotAuth>
                }
              />
              <Route
                path="*"
                element={
                  <RequireAuth>
                    <DefaultLayout />
                  </RequireAuth>
                }
              />
            </Routes>
          </React.Suspense>
        </ApolloProvider>
      </AnchorScroller>
    </HashRouter>
  )
}

export default App

const RequireAuth = ({ children }) => {
  const isLoggedIn = useReactiveVar(isLoggedInVar)
  let location = useLocation()

  if (!isLoggedIn) {
    return <Navigate to="/login" state={{ from: location }} replace />
  }

  return children
}

const RequireNotAuth = ({ children }) => {
  const isLoggedIn = useReactiveVar(isLoggedInVar)
  let location = useLocation()

  if (isLoggedIn) {
    return <Navigate to="/dashboard" state={{ from: location }} replace />
  }

  return children
}
